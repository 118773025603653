<template>
  <div>
    <gmap-map
      :center="center"
      :zoom="this.zoom"
      style="width:100%;  height: 400px;"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: ['lat', 'lng', 'zoom'],
  data() {
    return {
      center: { lat: this.lat, lng: this.lng },
      markers: []
    };
  },

  mounted() {
    this.addMarker();
  },

  methods: {
    addMarker() {
        const marker = {
          lat: this.lat,
          lng: this.lng
        };
        this.markers.push({ position: marker });
        this.center = marker;
    }
  }
};
</script>

<style lang="css">
  .vue-map-container {
    position: relative;
  }

  .vue-map-container .vue-map {
    left: 0; right: 0; top: 0; bottom: 0;
    position: absolute;
  }
  .vue-map-hidden {
    display: none;
  }
</style>