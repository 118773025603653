<template>
    <div>
        <div class="flex flex-no-wrap border-b border-copper-200 mt-2 md:hidden" @click="showContents">
            <font-awesome-icon class="text-copper-300 self-center mr-1" icon="filter"></font-awesome-icon>
            <p class="m-0 font-bold">Filters</p>
        </div>
        <transition-group name="dropdown" tag="div" class="dropdown" :style="dropdown">
            <div class="md:float-right" key="dropdown" ref="filterbar">
                <ul class="news-filter-list md:flex md:flex-row lg:flex-no-wrap pt-3 md:pt-0 mb-0">
                    <li class="px-3 text-sm md:border-l border-copper-200 cursor-pointer pb-1 md:pb-0"
                    @click="updateActiveFilter('*')"
                    :class="{ 'font-bold': activeFilter == '' || activeFilter == '*' }">All</li>
                    <li class="px-3 text-sm md:border-l border-copper-200 cursor-pointer pb-1 md:pb-0"
                        v-for="cat in categories"
                        :key="cat.id"
                        :class="{ 'font-bold': activeFilter == cat.slug }"
                        @click="updateActiveFilter(cat.slug)">
                        {{ cat.title }}
                    </li>
                </ul>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { NEWS_CATEGORIES } from '../constants/graphql'
export default {
    name: "NewsFilter",
    props: ['totalEntries', 'filteredEntryCount'],
    data() {
        return {
            categories: [],
            dropdown: {
                height: this.$root.isTrulyMobile ? 0 : 'auto'
            },
            slideActive: false,
            activeFilter: ''
        }
    },
    computed: {
        determineFilterBarState() {
            return this.$root.isTrulyMobile ? this.slideActive : true
        }
    },
    mounted() {
        window.addEventListener('resize', this.showContents);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.showContents);
    },
    methods: {
        updateActiveFilter(query) {
            this.$root.$emit("filterUpdated", query);
            this.activeFilter = query;
            if (query == "*") {
                this.$root.$emit("updateTotalCount", this.totalEntries);
            }
            else {
                this.filteredEntryCount.forEach(el => {
                    if (query == el.slug) {
                        this.$root.$emit("updateTotalCount", el.count);
                    }
                });
            }
        },
        showContents() {
            this.slideActive = !this.slideActive;
            if (this.$root.isTrulyMobile) {
                if (!this.slideActive) {
                    this.dropdown.height = 0
                } else {
                    this.dropdown.height = `${this.$refs.filterbar.clientHeight + 16}px`
                }
            } else {
                this.dropdown.height = `${this.$refs.filterbar.clientHeight + 16}px`
            }
        }
    },
    apollo: {
        categories: {
            query: NEWS_CATEGORIES,
            variables() {
                return {
                    group: "newsCategories"
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .news-filter-list {
        transition: all 275ms;
        @apply relative z-1;
    }
    .dropdown {
        position: relative;
        height: 0;
        @apply overflow-hidden;
        transition: height 350ms;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1rem;
        }

        &-enter,
        &-leave-to { opacity: 0 }

        &-leave,
        &-enter-to { opacity: 1 }

        &-enter-active,
        &-leave-active {
            position: absolute;
            width: 100%;
            transition: opacity 200ms ease-in-out;
        }

        &-enter-active { transition-delay: 100ms }
    }
</style>