<template>
  <div class="people-impacted-slider">
    <vue-glide
      v-if="slides"
      :options="options"
      ref="slider"
      v-model="active"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-duration="800"
    >
      <vue-glide-slide v-for="entry in slides" :key="entry.id">
        <div class="flex flex-wrap lg:flex-no-wrap md:-mx-10">
          <div
            class="w-full px-8 mb-8 md:w-1/2 md:mb-0 lg:px-10 lg:flex lg:items-center"
          >
            <picture v-if="entry.srcsetWebp && entry.srcset">
              <source
                :data-srcset="entry.srcsetWebp"
                data-sizes="100vw"
                type="image/webp"
              />
              <img
                class="w-full lazyload"
                :src="entry.placeholderBox"
                :data-srcset="entry.srcset"
                sizes="100vw"
              />
            </picture>
            <img v-else :src="entry.imageUrl" :alt="entry.altDesc" />
          </div>
          <div class="w-full px-8 md:w-1/2 lg:px-10 lg:flex lg:items-center">
            <figure>
              <blockquote
                v-if="entry.quote"
                class="mb-6 text-3xl font-medium leading-tight font-gest text-header"
              >
                <img
                  class="float-left mr-3 -mt-2"
                  src="/assets/img/svg/gest-quote.svg"
                  alt=""
                />
                {{ entry.quote }}
              </blockquote>
              <figcaption>
                <p v-if="entry.author" class="m-0 font-bold leading-snug">
                  {{ entry.author }}
                </p>
                <p v-if="entry.position" class="m-0 leading-snug">
                  {{ entry.position }}
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </vue-glide-slide>
      <template class="glide__arrows" slot="control">
        <button
          class="float-left px-3 py-1 mr-5 focus:outline-none"
          data-glide-dir="<"
        >
          <img src="/assets/img/svg/gest-arrow-slider-left.svg" alt="" />
        </button>
        <button
          class="float-right px-3 py-1 mr-5 focus:outline-none"
          data-glide-dir=">"
        >
          <img src="/assets/img/svg/gest-arrow-slider-right.svg" alt="" />
        </button>
      </template>
    </vue-glide>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";

export default {
  name: "PeopleImpactedSlider",
  props: {
    slides: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      entries: [],
      active: 0,
      options: {
        perView: 1,
        focusAt: "center",
        gap: 70,
        autoplay: 8000,
      },
    };
  },
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  methods: {
    moveToSlide(i) {
      this.$refs.slider.glide.go("=".concat(i));
    },
  },
};
</script>

<style lang="scss" scoped>
.people-impacted-slider {
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1;
  }
  .glide {
    @apply relative;
  }
  div[data-glide-el="controls"] {
    @screen lg {
      @apply block;
    }
    @apply hidden;
    position: absolute;
    width: 100%;
    top: 40%;
    left: 0;
  }
  .glide * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  .glide__slides,
  .glide__track {
    overflow: hidden;
  }
  .glide__slides {
    @screen lg {
      @apply items-center mb-0;
    }
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-touch-action: pan-Y;
    touch-action: pan-Y;
    padding: 0;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    will-change: transform;
  }
  .glide__slide,
  .glide__slides--dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide__slide {
    width: 100%;
    height: 100%;
    @apply py-3 mb-10;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    white-space: normal;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    @screen lg {
      @apply px-24;
    }
  }
  .glide__slide a {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .glide__arrows,
  .glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide--rtl {
    direction: rtl;
  }
}
</style>
