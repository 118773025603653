import Vue from "vue";
import Components from "./components";
import Modernizr from './modernizr';
import "./scss/app.scss";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronRight, faChevronLeft, faEnvelope, faMapMarkerAlt, faCheck, faFilter, faPlay, faTimesCircle, faArrowDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'url-search-params-polyfill';

// Third Party Components/Libraries
import SocialSharing from 'vue-social-sharing';
import SlideUpDown from 'vue-slide-up-down';
import Scrollactive from 'vue-scrollactive';
import Affix from 'vue-affix';

import { ApolloClient } from 'apollo-client';
import fetch from 'isomorphic-unfetch';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../fragmentTypes.json';
import VueApollo from 'vue-apollo';

import * as VueGoogleMaps from 'vue2-google-maps';
import VueGlide from 'vue-glide-js';
import VModal from 'vue-js-modal'
import AOS from 'aos';
import VueLazyLoad from 'vue-lazyload';
import VueMatchHeights from 'vue-match-heights';
// import LightBox from 'vue-image-lightbox';

// Image dependencies
import 'lazysizes/plugins/bgset/ls.bgset.js';
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js';
import 'lazysizes/plugins/respimg/ls.respimg.js';
import lazySizes from 'lazysizes';
import picturefill from 'picturefill';
import { setTimeout } from "optimism";

// Appolo config
// Fix 'invariant.esm.js?a957:29 WARNING: heuristic fragment matching going on!' error
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});
const httpLink = new HttpLink({
  //uri: process.env.VUE_SITE_API
  uri: "https://" + window.location.hostname + '/api'
})
const apolloClient = new ApolloClient({
  fetchOptions: { fetch },
  link: httpLink,
  cache: new InMemoryCache({ fragmentMatcher }),
  connectToDevTools: true
})
Vue.use(VueApollo)
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading'
  }
})
// Init and add fontawesome icons
library.add (
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faEnvelope,
  faMapMarkerAlt,
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faCheck,
  faFilter,
  faPlay,
  faTimesCircle,
  faArrowDown,
  faExclamationCircle
);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

// Register global components
Vue.use(Affix);
Vue.use(VueLazyLoad);
Vue.use(VModal);
Vue.use(VueGlide);
Vue.use(SocialSharing);
Vue.use(Scrollactive);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY
  }
});
Vue.use(VueMatchHeights, {
  disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

const app = () => {
  new Vue({
    el: "#app",
    apolloProvider,
    delimiters: ["${", "}"],
    data() {
      return {
        showMenuNav: false,
        menuLinkClosed: true,
        mobileNavActive: false,
        notify: false,
        windowWidth: window.innerWidth
      }
    },
    components: {
      SocialSharing,
      SlideUpDown,
      Scrollactive,
      // LightBox
    },
    computed: {
      isMobile() {
        return this.windowWidth <= 1024
      },
      isTrulyMobile() {
        return this.windowWidth <= 768
      },
      affixShouldBeEnabled() {
        return !this.isMobile ? true : false;
      },
      isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
        {
            return true;
        }
        else  // If another browser, return 0
        {
            return false;
        }
      }
    },
    mounted() {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();

      picturefill();
      lazySizes.init();

      // You can also pass an optional settings object
      // below listed default settings
      AOS.init({
        // Global settings:
        disable: this.isIE ? true : false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

      });

      // Branding... because that's what we do 🚀⚛️
      if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
          var t = ["\n %c Made with ♥ by Rockit Science Agency %c %c %c https://www.rockitscienceagency.com/ %c %c🚀⚛️ \n\n", "color: #7ec156; background: #202c31; padding:5px 0;", "background: #494949; padding:5px 0;", "background: #494949; padding:5px 0;", "color: #fff; background: #1c1c1c; padding:5px 0;", "padding:5px 0;", "color: #b0976d; padding:5px 0;"];
          window.console.log.apply(console, t);
      } else {
          window.console && window.console.log("Made with love ♥ Rockit Science Agency - https://www.rockitscienceagency.com/ 🚀⚛️");
      }

      // "Accordion" logic
      if (this.isMobile) {
        let myRadios = document.getElementsByName('accordions');
        let setCheck;
        let x = 0;
        for(x = 0; x < myRadios.length; x++){
          // This opens up the first item
          // if (x == 0) {
          //   myRadios[x].checked = true;
          // }
          myRadios[x].onclick = function() {
            if (setCheck != this){
                setCheck = this;
            } else {
              this.checked = false;
              setCheck = null;
            }
          };
        }
      }

      // Controls the notifier when a user successfully subscribes
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      if (params.get("subscribed")) {
        this.notifySubscriber();
      }

    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
    },
    methods: {
      handleSubmit(event) {
        this.$emit("printFormSubmitted", event);
      },
      handleBodySignUpSubmit(event) {
        this.$emit("handleBodySignUpSubmit", event);
      },
      handleMenuSignUpSubmit(event) {
        this.$emit("handleMenuSignUpSubmit", event)
      },
      toggleMenuNav() {
        this.showMenuNav = !this.showMenuNav;
      },
      closeMenuLink() {
        this.menuLinkClosed = true;
      },
      openMenuLink() {
        this.menuLinkClosed = false;
      },
      activateMobileNav() {
        this.mobileNavActive = !this.mobileNavActive;
      },
      getWindowWidth() {
        this.windowWidth = window.innerWidth;
      },
      notifySubscriber() {
        this.notify = true;
        setTimeout(() => {
          this.notify = false;
        }, 5000);
      },
      showModal(modalName) {
          this.$modal.show(modalName);
      },
      beforeOpen(event) {
        document.getElementsByTagName('html')[0].classList.add('v--modal-block-scroll');
      },
      beforeClose(event) {
        document.getElementsByTagName('html')[0].classList.remove('v--modal-block-scroll');
      }
    },
  });
}

// Loads JS asynchronously
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', app); // Document still loading so DomContentLoaded can still fire
} else {
  app()
}