<template>
  <div class="w-full">
    <div v-if="isProcessing" class="mb-6 block w-full">
        <svg
            id="loadingIcon"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="50" cy="50" r="45" />
        </svg>
    </div>
    <div v-else-if="hasPassed" class="bg-gest-blue-light p-3 text-center">
        <p class="text-white text-sm m-0">Thanks for subscribing!</p>
    </div>
    <div v-else-if="hasFailed" class="bg-gest-blue-light p-3 text-center">
        <font-awesome-icon class="text-white self-center text-4xl" icon="exclamation-circle"></font-awesome-icon>
        <p class="text-white text-sm m-0"><strong>Something went wrong</strong><br /> Please <a :href="`mailto:${helpEmail}`" class="text-white font-bold underline hover:text-white">contact us</a> about this issue or <span @click="returnToForm()" class="font-bold underline cursor-pointer">try again</span>.</p>
    </div>
    <template v-else>
        <slot name="form"></slot>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GestSignUp",
  data() {
    return {
      entries: [],
      active: false,
      isProcessing: false,
      hasPassed: false,
      hasFailed: false,
      helpEmail: "Courtney.Fos@lmoga.com",
    };
  },
  mounted() {
    this.$root.$on("handleGestSignUpSubmit", $event => {
        this.submit($event);
    });
  },
  methods: {
    submit(event) {
      this.isProcessing = true;
      let _this = this;
      let form = event.target;
      let data = new FormData(form);
      let method = form.getAttribute("method");
      let action = form.getAttribute("action");
      axios({
        url: action ? action : window.location.href,
        method: method ? method : "post",
        data: data,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          HTTP_X_REQUESTED_WITH: "XMLHttpRequest"
        }
      })
        .then(function(responseObject) {
          var response = responseObject.data;
          if (response.success && response.finished) {
            form.reset();
            _this.isProcessing = false;
            _this.hasFailed = false;
            _this.hasPassed = true;
          } else if (response.errors || response.formErrors) {
            console.log("Field Errors", response.errors);
            console.log("Form Errors", response.formErrors);
            _this.isProcessing = false;
            _this.hasPassed = false;
            _this.hasFailed = true;
          }
          // Update the Honeypot field if using JS enhancement
          if (response.honeypot) {
            var honeypotInput = form.querySelector(
              "input[name^=freeform_form_handle]"
            );
            honeypotInput.setAttribute("name", response.honeypot.name);
            honeypotInput.setAttribute("id", response.honeypot.name);
            honeypotInput.value = response.honeypot.hash;
          }
        })
        .catch(function(error) {
          _this.isProcessing = false;
          _this.hasPassed = false;
          _this.hasFailed = true;
          console.error(error);
        });
    },
    returnToForm() {
        this.hasPassed = false;
        this.hasFailed = false;
        this.isProcessing = false;
    }
  }
};
</script>

<style scoped lang="scss">
// SVG styles.
#loadingIcon {
  animation: 2s linear infinite svg-animation;
  max-width: 75px;
  margin: 0 auto;
}

// SVG animation.
@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Circle styles.
circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: #eddcb7;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

// Circle animation.
@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>
