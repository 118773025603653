<template>
    <affix class="terminology-menu border-copper-200 border px-6 py-6 inline-block mx-auto bg-white shadow-xl font-hairline text-xl" relative-element-selector="#terminology" style="width: auto; max-width: 1280px" :offset="{ top: 60, bottom: 40 }">
        <scrollactive class="menu-list" active-class="active" :offset="0">
            <ul class="border border-t-0 border-b-0 border-copper-300 px-2 mb-0">
                <li class="inline-block mx-1" v-for="(entry, index) in entries">
                    <a class="scrollactive-item px-2 py-1" :href="'#' + entry.title">{{ entry.title }}</a>
                </li>
            </ul>
        </scrollactive>
    </affix>
</template>

<script>

import { TERMINOLOGY } from '../constants/graphql'
import { Affix } from 'vue-affix';

export default {
    name: 'TerminologyJumpNav',
    data() {
        return {
            entries: [],
            loading: 0
        }
    },
    components: {
        Affix
    },
    apollo: {
        entries: {
            query: TERMINOLOGY,
            variables() {
                return {
                    section: "terminologyEntries",
                    order: "title",
                    limit: "-1"
                }
            }
        }
    }
}


</script>