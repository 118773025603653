<template>
    <div class="w-full md:px-6">
        <div v-show="loadingFilteredResults == 1" class="mb-6 block">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="45"/>
            </svg>
        </div>
        <ul v-if="loadingFilteredResults == 0" class="mb-12">
            <li class="border-b border-gest-blue-light relative" v-for="(entry, index) of entries" :key="index">
                <div class="flex flex-wrap items-center md:-mr-6 pb-6" :class="{ 'pt-12': index > 0 }">
                    <div class="flex-initial text-center mx-3 md:mx-6" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                        <span class="font-san font-medium block uppercase">{{ entry.postDate | month }}</span>
                        <span class="font-gest block text-6xl text-gest-blue leading-none font-bold">{{ entry.postDate|day }}</span>
                        <span class="font-san font-medium block">{{ entry.postDate | year }}</span>
                    </div>
                    <div class="w-2/6 mx-3 md:mx-6 flex-grow" data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
                        <h3 class="font-gest font-medium mb-6"><a v-bind:href="entry.uri">{{ entry.title }}</a></h3>
                        <p class="mb-0" v-text="removeTags(entry.newsExcerpt).substring(0,300)+'...'"></p>
                    </div>
                </div>
                <div class="w-full mb-1 aos-init aos-animate flex justify-end" data-aos="fade-left" data-aos-delay="200" data-aos-duration="800">
                    <div>
                        <span class="inline mr-3 font-light text-gray-400">Share</span>
                        <social-sharing :url="siteUrl + entry.uri" inline-template class="inline">
                            <div>
                                <network class="mr-3 cursor-pointer outline-none" network="facebook">
                                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }" class="text-copper-300"></font-awesome-icon>
                                </network>
                                <network class="mr-3 cursor-pointer outline-none" network="twitter">
                                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" class="text-copper-300"></font-awesome-icon>
                                </network>
                                <network class="cursor-pointer outline-none" network="email">
                                    <font-awesome-icon icon="envelope" class="text-copper-300"></font-awesome-icon>
                                </network>
                            </div>
                        </social-sharing>
                    </div>
                </div>
            </li>
        </ul>
        <div class="flex justify-center">
            <button
                v-if="moreEntries && loadingFilteredResults == 0"
                @click="loadMore()"
                class="py-2 px-10 font-bold rounded bg-copper-300 text-white hover:text-white focus:outline-none">
                {{ loading ? 'Loading...' : 'Load More' }}
            </button>
        </div>
    </div>
</template>

<script>

import { GEST_NEWS_QUERY } from '../constants/graphql'
import moment from 'moment';

const INITIAL_ENTRIES_PER_PAGE = 4
const FILTERED_ENTRIES_PER_PAGE = 10

export default {
    name: 'GestNewsListing',
    props:['allNews', 'siteUrl'],
    data() {
        return {
            entries: [],
            totalCount: this.allNews,
            loading: 0,
            loadingFilteredResults: 0,
            qVariables: {
                section: "gestNewsEntries",
                limit: INITIAL_ENTRIES_PER_PAGE,
                order: "postDate desc",
                offset: 0
            }
        }
    },
    mounted() {
        this.$root.$on('filterUpdated', ($event) => {
            this.fetchFilteredEntries($event);
            this.loadingFilteredResults = 1;
        });
        this.$root.$on('updateTotalCount', ($event) => {
            this.totalCount = $event;
        });
    },
    computed: {
        moreEntries() {
            return this.entries.length < this.totalCount
        },
    },
    compontents: {
        moment
    },
    filters: {
        month: function(data) {
            return moment(data).format('MMMM');
        },
        day: function(data) {
            return moment(data).format('DD');
        },
        year: function(data) {
            return moment(data).format('YYYY');
        }
    },
    apollo: {
        entries: {
            query: GEST_NEWS_QUERY,
            variables() {
                return this.qVariables
            },
            // Will update the 'loading' attribute
            // +1 when a new query is loading
            // -1 when a query is completed
            loadingKey: 'loading',
            result ({ data, loading, networkStatus }) {
                console.log(data)
                console.log(loading)
                console.log(networkStatus)
                this.loadingFilteredResults = 0;
            }
        }
    },
    methods: {
        loadMore() {
            this.$apollo.queries.entries.fetchMore({
                variables: {
                    offset: this.entries.length
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }
                    return Object.assign({}, previousResult, {
                        // Using the 'spread operator (...)' here to retain the previous results while adding the new results
                        // A good description for the spread operator: https://oprea.rocks/blog/what-do-the-three-dots-mean-in-javascript/
                        entries: [...previousResult.entries, ...fetchMoreResult.entries]
                    })
                }
            })
        },
        fetchFilteredEntries(query) {
            this.$apollo.queries.entries.refetch({
                search: query,
                limit: FILTERED_ENTRIES_PER_PAGE
            })
        },
        removeTags(string) {
           let regex = /(<([^>]+)>)/ig;
           return string.replace(regex, "");
        }
    }
}
</script>

<style scoped lang="scss">

    // SVG styles.
    svg {
        animation: 2s linear infinite svg-animation;
        max-width: 75px;
        margin: 0 auto;
    }

    // SVG animation.
    @keyframes svg-animation {
        0% {
            transform: rotateZ(0deg);
        }
        100% {
            transform: rotateZ(360deg)
        }
    }

    // Circle styles.
    circle {
        animation: 1.4s ease-in-out infinite both circle-animation;
        display: block;
        fill: transparent;
        stroke: #eddcb7;
        stroke-linecap: round;
        stroke-dasharray: 283;
        stroke-dashoffset: 280;
        stroke-width: 10px;
        transform-origin: 50% 50%;
    }

    // Circle animation.
    @keyframes circle-animation {
        0%, 25% {
            stroke-dashoffset: 280;
            transform: rotate(0);
        }

        50%, 75% {
            stroke-dashoffset: 75;
            transform: rotate(45deg);
        }

        100% {
            stroke-dashoffset: 280;
            transform: rotate(360deg);
        }
    }
</style>
