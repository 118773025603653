<template>
    <div>
        <div class="py-2 px-10 border border-white font-bold rounded text-white hover:text-copper-300 hover:bg-white hover:text-copper-300 transition-bg transition-750 cursor-pointer inline" @click="openGallery(0)">
        View Gallery</div>
        <LightBox
            :images="images"
            ref="lightbox"
            :show-caption="true"
            :show-thumbs="false"
            :show-light-box="false"
        >
        </LightBox>
    </div>
</template>

<script>
import LightBox from 'vue-image-lightbox';
export default {
    name: "Gallery",
    props: ['images'],
    components: {
        LightBox
    },
    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index);
        }
    }
}
</script>

<style style="scss">
    @import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
    .vue-lb-arrow:focus {
        @apply outline-none;
    }
</style>