<template>
    <div class="md:w-full">
        <h3 class="custom-heading-line mb-12 font-serif font-bold text-4xl">Past Presidents</h3>
        <div class="flex">
            <ul class="md:w-1/2" v-for="column in columns">
                <li class="mb-8 flex" v-for="item in column">
                    <div class="w-1/3 text-right pr-12 text-copper-300">
                        {{ item.pastPresidentsDateRange }}
                    </div>
                    <div class="w-2/3">
                        <p class="leading-relaxed">
                            <span class="font-bold">{{ item.pastPresidentsName }}</span><br>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    import { PASTPRESIDENTS } from '../constants/graphql';

    export default {
        name: 'PastChairmen',
        data() {
            return {
                entries: [],
                cols: 2,
                loading: 0
            }
        },
        computed: {
            columns () {
                let columns = []
                let mid = Math.ceil(this.entries.length / this.cols)
                for (let col = 0; col < this.cols; col++) {
                    columns.push(this.entries.slice(col * mid, col * mid + mid))
                }
                return columns
            }
        },
        apollo: {
            entries: {
                query: PASTPRESIDENTS,
                variables() {
                    return {
                        "section": "pastPresidents",
                        "limit": "-1"
                    }
                }
            }
        }
    }
</script>