<template>
  <div class="flex items-center justify-center w-full h-full text-header">
    <div class="relative block max-w-xs text-center card text-header">
      <div class="card-inner" @click="toggleState" :class="{ flip: active }">
        <a class="block w-full h-full front" :class="theme">
          <div class="p-12">
            <h3>{{ heading }}</h3>
            <hr class="w-20 mx-auto mb-4 border border-t-0 border-copper-300" />
            <div v-html="description"></div>
          </div>
          <div
            class="absolute bottom-0 right-0 w-8 h-8 bg-copper-300 info-card-grow"
          >
            <div
              class="absolute bottom-0 left-0 flex items-center justify-center w-8 h-8"
            >
              <font-awesome-icon
                class="self-center text-white"
                icon="chevron-right"
              ></font-awesome-icon>
            </div>
          </div>
        </a>
        <div
          class="flex items-center justify-center w-full h-full border back bg-copper-100 border-copper-300"
          :class="{
            'absolute top-0 left-0': preserve3d,
            hidden: !preserve3d
          }"
        >
          <div v-if="isProcessing" class="block w-full mb-6">
            <svg
              id="loadingIcon"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
          <div v-if="hasPassed" class="p-10">
            <p class="leading-normal no-margin">Thanks for following!</p>
            <p class="text-xs leading-normal text-gray-600">
              Stay tuned for future emails.
            </p>
          </div>
          <div
            v-show="(!isProcessing || hasFailed) && !hasPassed"
            class="relative flex flex-col items-center w-full h-full p-4 pt-12"
          >
            <p class="text-xs leading-normal" v-if="!hasFailed">
              Enter your email address to stay up to date with LMOGA
            </p>
            <p class="text-xs leading-normal text-red-600" v-if="hasFailed">
              There seems to be an error with your request. Try again, or
              <a
                :href="`mailto:${helpEmail}`"
                class="text-blue-700 hover:text-blue-800"
                >email us</a
              >
              about this problem
            </p>
            <slot name="form"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "NewsletterSignup",
  props: ["heading", "description", "theme", "formName", "formHandle"],
  data() {
    return {
      entries: [],
      active: false,
      isProcessing: false,
      hasPassed: false,
      hasFailed: false,
      helpEmail: "Courtney.Fos@lmoga.com",
      preserve3d: Modernizr.preserve3d
    };
  },
  mounted() {
    // OLD CODE
    // if (this.formName == "newsletterMenuSignup") {
    //   this.$root.$on("handleMenuSignUpSubmit", $event => {
    //     this.submit($event);
    //   });
    // }

    // if (this.formName == "newsletterBodySignup") {
    //   this.$root.$on("handleBodySignUpSubmit", $event => {
    //     this.submit($event);
    //   });
    // }

    /**
     * NEW CODE - RECAPTCHA FINALLY PLAYS NICELY
     * Listens for freeform submit events instead of manual approach.
     * Our manual approach via axios resulted in reCAPTCHA failing to validate
     * the form submission each time.
     */
    const _this = this;
    const form = document.getElementById(this.formHandle);
    form.addEventListener('freeform-ajax-success', function(event) {
      const response = event.response;
      form.reset();
      _this.isProcessing = false;
      _this.hasFailed = false;
      _this.hasPassed = true;
    });

    form.addEventListener('freeform-ajax-error', function(event) {
      // Do whatever checks on errors you need to do, if any
      const errors = event.errors;
      const formErrors = event.formErrors;
      const response = event.response;
      console.log("Field Errors", response.errors);
      console.log("Form Errors", formErrors);
      _this.isProcessing = false;
      _this.hasPassed = false;
      _this.hasFailed = true;
    });

  },
  methods: {
    // OLD CODE (MANUAL APPROACH - HAS RECAPTCHA ISSUES)
    // submit(event) {
    //   this.isProcessing = true;
    //   let _this = this;
    //   let form = event.target;
    //   let data = new FormData(form);
    //   let method = form.getAttribute("method");
    //   let action = form.getAttribute("action");
    //   axios({
    //     url: action ? action : window.location.href,
    //     method: method ? method : "post",
    //     data: data,
    //     headers: {
    //       "X-Requested-With": "XMLHttpRequest",
    //       HTTP_X_REQUESTED_WITH: "XMLHttpRequest"
    //     }
    //   })
    //     .then(function(responseObject) {
    //       var response = responseObject.data;
    //       if (response.success && response.finished) {
    //         form.reset();
    //         _this.isProcessing = false;
    //         _this.hasFailed = false;
    //         _this.hasPassed = true;
    //       } else if (response.errors || response.formErrors) {
    //         console.log("Field Errors", response.errors);
    //         console.log("Form Errors", response.formErrors);
    //         _this.isProcessing = false;
    //         _this.hasPassed = false;
    //         _this.hasFailed = true;
    //       }
    //       // Update the Honeypot field if using JS enhancement
    //       if (response.honeypot) {
    //         var honeypotInput = form.querySelector(
    //           "input[name^=freeform_form_handle]"
    //         );
    //         honeypotInput.setAttribute("name", response.honeypot.name);
    //         honeypotInput.setAttribute("id", response.honeypot.name);
    //         honeypotInput.value = response.honeypot.hash;
    //       }
    //     })
    //     .catch(function(error) {
    //       _this.isProcessing = false;
    //       _this.hasPassed = false;
    //       _this.hasFailed = true;
    //       console.error(error);
    //     });
    // },
    toggleState() {
      if (!this.active) {
        this.active = !this.active;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.preserve3d {
  .card-inner {
    transition: 0.6s;
    transform-style: preserve-3d;
    &.flip {
      transform: rotateY(180deg);
    }
  }
}

.no-preserve3d {
  .card-inner {
    &.flip {
      .front {
        display: none;
      }
      .back {
        display: block;
      }
    }
  }
}
.card {
  .preserve3d & {
    perspective: 1000px;
    transform-style: preserve-3d;
    transform-origin: 10% 50%;
    // Moving
    &-move {
      transition: all 600ms ease-in-out 50ms;
    }

    // Appearing
    &-enter-active {
      transition: all 300ms ease-out;
    }

    // Dissapearing
    &-leave-active {
      transition: all 200ms ease-in;
      position: absolute;
      z-index: 0;
    }

    // Appear at / dissapear to
    &-enter,
    &-leave-to {
      opacity: 0;
    }
    &-enter {
      transform: scale(0.9);
    }
  }
}

.front,
.back {
  backface-visibility: hidden;
  .preserve3d & {
    transition: 0.6s;
    transform-style: preserve-3d;
  }
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  .preserve3d & {
    transform: rotateY(0deg);
  }
}

/* back, initially hidden pane */
.back {
  .preserve3d & {
    transform: rotateY(180deg);
  }
}
// SVG styles.
#loadingIcon {
  animation: 2s linear infinite svg-animation;
  max-width: 75px;
  margin: 0 auto;
}

// SVG animation.
@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Circle styles.
circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: #eddcb7;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

// Circle animation.
@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>
