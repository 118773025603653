import gql from "graphql-tag";

export const NEWS_QUERY = gql`
  query(
    $section: [String]
    $search: String
    $limit: Int!
    $offset: Int!
    $order: String
  ) {
    entries(
      limit: $limit
      section: $section
      search: $search
      offset: $offset
      orderBy: $order
    ) {
      uri
      title
      postDate
      ... on newsEntries_news_Entry {
        newsExcerpt
        authorsName
        externalPublicationsName
        externalPublicationsUrl
        newsCategory {
          id
          title
        }
      }
    }
  }
`;
export const GEST_NEWS_QUERY = gql`
  query(
    $section: [String]
    $limit: Int!
    $offset: Int!
    $order: String
  ) {
    entries(
      site: "*"
      limit: $limit
      section: $section
      offset: $offset
      orderBy: $order
    ) {
      uri
      title
      postDate
      ... on gestNewsEntries_gestNewsEntries_Entry {
        newsExcerpt
      }
    }
  }
`;
export const BLOG_QUERY = gql`
  query($section: [String], $limit: Int!, $offset: Int!, $order: String) {
    entries(
      section: $section
      offset: $offset
      orderBy: $order
      limit: $limit
    ) {
      uri
      title
      postDate
      ... on blogEntries_blogEntries_Entry {
        newsExcerpt
        blogAuthor {
          ... on blogAuthor_addAuthor_BlockType {
            staffMember {
              url
              ... on staffBios_staffBios_Entry {
                staffFullName
              }
            }
          }
          ... on blogAuthor_createNewAuthor_BlockType {
            blogAuthor {
              ... on blogAuthor_BlockType {
                authorName
              }
            }
          }
        }
        blogImage {
          ... on blogImages_Asset {
            optimizedBlog {
              src
              srcset
              srcWebp
              srcsetWebp
              placeholderImage
              placeholderBox
              placeholderSilhouette
            }
          }
        }
      }
    }
  }
`;
export const NEWS_CATEGORIES = gql`
  query($group: [String]) {
    categories(group: $group) {
      title
      slug
      id
    }
  }
`;
export const TERMINOLOGY = gql`
  query($section: [String], $order: String) {
    entries(section: $section, orderBy: $order) {
      ... on terminologyEntries_terminology_Entry {
        title
      }
    }
  }
`;
export const BOARDOFDIRECTORS = gql`
  query($section: [String]) {
    entries(section: $section) {
      ... on boardOfDirectorsEntries_boardOfDirectors_Entry {
        boardMember
        companyName
        companyUrl
      }
    }
  }
`;
export const PASTCHAIRMEN = gql`
  query($section: [String]) {
    entries(section: $section) {
      ... on pastChairmen_pastChairmen_Entry {
        pastChairmanName
        pastChairmanCompany
        pastChairmanCompanyUrl
        pastChairmanDateRange
      }
    }
  }
`;
export const PASTPRESIDENTS = gql`
  query($section: [String]) {
    entries(section: $section) {
      ... on pastPresidents_pastPresidents_Entry {
        pastPresidentsName
        pastPresidentsDateRange
      }
    }
  }
`;
export const TIMELINE = gql`
  query($section: [String], $order: String, $limit: Int!) {
    entries(section: $section, orderBy: $order, limit: $limit) {
      ... on lmogasHistory_lmogasHistory_Entry {
        id
        title
        uri
        timelineDescription
        timelinePhoto {
          ... on timelineImages_Asset {
            optimizedTimeline {
              src
              srcset
              srcWebp
              srcsetWebp
              placeholderImage
              placeholderBox
              placeholderSilhouette
            }
          }
        }
      }
      ... on lmogasHistory_lmogasHistory_Entry {
        id
      }
    }
  }
`;
