<template>
  <div class="w-full md:px-6">
    <ul class="flex flex-wrap -mx-3 md:-mx-6">
      
      <!-- Latest Blog Posts -->
      <latest-blog-post />

      <!-- All Posts -->
      <li
        v-for="(entry, index) of entries"
        class="relative w-full px-3 md:w-1/2 md:px-6"
        :key="index"
      >
        <div class="flex flex-wrap pb-6 -mx-3 md:-mx-6">
          <div
            class="flex-grow w-2/6 mx-3 md:mx-6"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="800"
          >
            <a v-if="entry.uri" :href="entry.uri">
              <picture v-if="entry.blogImage.length > 0">
                <source
                  :data-srcset="entry.blogImage[0].optimizedBlog.srcsetWebp"
                  data-sizes="100vw"
                  type="image/webp"
                />
                <img
                  class="lazyload"
                  :src="entry.blogImage[0].optimizedBlog.src"
                  :data-srcset="entry.blogImage[0].optimizedBlog.srcset"
                  sizes="100vw"
                />
              </picture>
            </a>
            <ul class="flex flex-no-wrap mb-3">
              <li v-if="entry.postDate" class="font-bold text-copper-300">
                {{ entry.postDate | formatDate }}
              </li>
              <li v-if="entry.blogAuthor.length > 0">
                <template v-if="entry.blogAuthor[0].staffMember">
                  <a :href="entry.blogAuthor[0].staffMember[0].url">
                    &nbsp;&#124;&nbsp;
                    {{ entry.blogAuthor[0].staffMember[0].staffFullName }}
                  </a>
                </template>
                <template v-if="entry.blogAuthor[0].blogAuthor">
                  &nbsp;&#124;&nbsp;
                  {{ entry.blogAuthor[0].blogAuthor[0].authorName }}
                </template>
              </li>
            </ul>
            <h3 v-if="entry.uri" class="font-bold">
              <a v-bind:href="entry.uri">{{ entry.title }}</a>
            </h3>
            <div v-if="entry.newsExcerpt" v-html="entry.newsExcerpt"></div>
          </div>
        </div>
      </li>
    </ul>
    <div class="flex justify-center">
      <button
        v-if="moreEntries"
        @click="loadMore()"
        class="px-10 py-2 font-bold border rounded border-copper-300 text-default hover:text-copper-300 focus:outline-none"
      >
        {{ loading ? "Loading..." : "Load More" }}
      </button>
    </div>
  </div>
</template>

<script>
import { BLOG_QUERY } from "../constants/graphql";
import moment from "moment";

const ENTRIES_PER_PAGE = 6;

export default {
  name: "BlogListing",
  props: ["allBlogs"],
  data() {
    return {
      entries: [],
      latestEntry: [],
      totalCount: this.allBlogs,
      loading: 0,
      qVariables: {
        section: "blogEntries",
        order: "postDate desc",
        offset: 1,
        limit: ENTRIES_PER_PAGE
      }
    };
  },
  computed: {
    moreEntries() {
      return this.entries.length + 1 < this.totalCount;
    }
  },
  compontents: {
    moment
  },
  filters: {
    formatDate: function(data) {
      return moment(data).format("MMM Do, YYYY");
    }
  },
  apollo: {
    entries: {
      variables() {
        return this.qVariables;
      },
      // Will update the 'loading' attribute
      // +1 when a new query is loading
      // -1 when a query is completed
      loadingKey: "loading",
      query: BLOG_QUERY
    },
  },
  methods: {
    loadMore() {
      this.$apollo.queries.entries.fetchMore({
        variables: {
          offset: this.entries.length
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          return Object.assign({}, previousResult, {
            // Using the 'spread operator (...)' here to retain the previous results while adding the new results
            // A good description for the spread operator: https://oprea.rocks/blog/what-do-the-three-dots-mean-in-javascript/
            entries: [...previousResult.entries, ...fetchMoreResult.entries]
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
// SVG styles.
svg {
  animation: 2s linear infinite svg-animation;
  max-width: 75px;
  margin: 0 auto;
}

// SVG animation.
@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Circle styles.
circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: #eddcb7;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

// Circle animation.
@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>
