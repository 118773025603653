<template>
  <div>
    <li
      v-for="(entry, index) of entries"
      class="w-full border-b border-copper-200 relative mb-10"
      :key="index"
    >
      <div class="flex flex-wrap items-center -mx-3 md:-mx-6 pb-10">
        <div
          class="w-full md:w-3/5 px-3 md:px-6 mb-6 md:mb-0"
          v-if="entry.blogImage"
        >
          <div
            class="bg-blue-700 py-2 px-5 absolute z-1"
            style="top: -26px;"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="800"
          >
            <p class="text-white text-xl mb-0">Latest Post</p>
          </div>
          <a :href="entry.uri">
            <picture>
              <source
                :data-srcset="entry.blogImage[0].optimizedBlog.srcsetWebp"
                data-sizes="100vw"
                type="image/webp"
              />
              <img
                class="lazyload"
                :src="entry.blogImage[0].optimizedBlog.src"
                :data-srcset="entry.blogImage[0].optimizedBlog.srcset"
                sizes="100vw"
              />
            </picture>
          </a>
        </div>
        <div class="w-full md:w-2/5 px-3 md:px-6">
          <ul class="flex flex-no-wrap mb-3">
            <li class="text-copper-300 font-bold">
              {{ entry.postDate | formatDate }}
            </li>
            <li v-if="entry.blogAuthor[0]">
              <template v-if="entry.blogAuthor[0].staffMember">
                <a :href="entry.blogAuthor[0].staffMember[0].url">
                  &nbsp;&#124;&nbsp;{{
                    entry.blogAuthor[0].staffMember[0].staffFullName
                  }}
                </a>
              </template>
              <template v-if="entry.blogAuthor[0].blogAuthor">
                &nbsp;&#124;&nbsp;{{
                  entry.blogAuthor[0].blogAuthor.authorName
                }}
              </template>
            </li>
          </ul>
          <h2 class="font-bold mb-2 text-4xl leading-tight">
            <a :href="entry.uri">{{ entry.title }}</a>
          </h2>
          <div v-if="entry.newsExcerpt" v-html="entry.newsExcerpt"></div>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import { BLOG_QUERY } from "../constants/graphql";
import moment from "moment";

export default {
  name: "LatestBlogPost",
  data() {
    return {
      entries: [],
      loading: 0,
      qVariables: {
        section: "blogEntries",
        order: "postDate desc",
        limit: 1,
        offset: 0
      }
    };
  },
  compontents: {
    moment
  },
  filters: {
    formatDate: function(data) {
      return moment(data).format("MMM Do, YYYY");
    }
  },
  apollo: {
    entries: {
      query: BLOG_QUERY,
      variables() {
        return this.qVariables;
      },
      // Will update the 'loading' attribute
      // +1 when a new query is loading
      // -1 when a query is completed
      loadingKey: "loading"
    }
  }
};
</script>
