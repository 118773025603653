<template>
  <div class="timeline">
    <div class="flex flex-wrap items-center w-full mt-8 lg:px-24">
      <h1
        class="mb-0 font-serifBold"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="800"
      >
        Our History
      </h1>
    </div>
    <vue-glide
      v-if="entries.length"
      :options="options"
      ref="slider"
      v-model="active"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-duration="800"
    >
      <vue-glide-slide v-for="entry in entries" :key="entry.id">
        <div class="flex flex-wrap -mx-3 lg:flex-no-wrap">
          <div
            class="w-full lg:w-1/2 lg:px-3 lg:flex lg:items-center"
            v-if="entry.timelinePhoto[0]"
          >
            <picture>
              <source
                :data-srcset="
                  entry.timelinePhoto[0].optimizedTimeline.srcsetWebp
                "
                data-sizes="100vw"
                type="image/webp"
              />
              <img
                class="w-full lazyload"
                :src="entry.timelinePhoto[0].optimizedTimeline.placeholderBox"
                :data-srcset="entry.timelinePhoto[0].optimizedTimeline.srcset"
                sizes="100vw"
              />
            </picture>
          </div>
          <div
            class="w-full lg:px-3 lg:flex lg:items-center"
            :class="{ 'lg:w-1/2': entry.timelinePhoto[0] }"
          >
            <div>
              <h2
                class="mb-0 font-serif leading-none text-copper-300 timeline__title"
                v-if="entry.title"
              >
                {{ entry.title }}
              </h2>
              <div
                class="mb-6 timeline__description"
                v-if="entry.timelineDescription"
                v-html="entry.timelineDescription"
                :class="{ 'text-sm': entry.timelinePhoto[0] }"
              ></div>
              <a
                href="/about-us"
                class="px-10 py-2 mx-6 font-bold border rounded border-copper-300 text-default hover:text-copper-300"
                >About Us</a
              >
            </div>
          </div>
        </div>
      </vue-glide-slide>
      <template class="glide__arrows" slot="control">
        <button
          class="float-left px-3 py-1 mr-5 focus:outline-none"
          data-glide-dir="<"
        >
          <slot name="arrowLeft"></slot>
        </button>
        <button
          class="float-right px-3 py-1 mr-5 focus:outline-none"
          data-glide-dir=">"
        >
          <slot name="arrowRight"></slot>
        </button>
      </template>
    </vue-glide>
    <div
      class="glide__bullets"
      data-aos="fade-up"
      data-aos-delay="400"
      data-aos-duration="800"
    >
      <template v-for="(entry, index) in entries">
        <button
          :key="index"
          @click="moveToSlide(index)"
          :data-glide-dir="index"
          class="relative glide__bullet"
          :class="{ 'glide__bullet--active': index == active }"
        >
          <span class="absolute text-xs text-gray-400 glide__bullet--year">{{
            entry.title
          }}</span>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { TIMELINE } from "../constants/graphql";
import { Glide, GlideSlide } from "vue-glide-js";

export default {
  name: "TimelineSlider",
  data() {
    return {
      entries: [],
      active: 0,
      options: {
        perView: 1,
        focusAt: "center",
        gap: 70,
        autoplay: 8000,
      },
    };
  },
  // computed: {
  //     isIE() {
  //         return this.$root.isIE;
  //     },
  // },
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  methods: {
    moveToSlide(i) {
      this.$refs.slider.glide.go("=".concat(i));
    },
  },
  apollo: {
    entries: {
      query: TIMELINE,
      variables() {
        return {
          section: "lmogasHistory",
          order: "title asc",
          limit: 10,
        };
      },
    },
  },
};
</script>

<style lang="scss">
.timeline {
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1;
  }
  .glide {
    @apply relative;
  }
  div[data-glide-el="controls"] {
    @screen lg {
      @apply block;
    }
    @apply hidden;
    position: absolute;
    width: 100%;
    top: 40%;
    left: 0;
  }
  .glide * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  .glide__slides,
  .glide__track {
    overflow: hidden;
  }
  .glide__slides {
    @screen lg {
      @apply items-center mb-0;
    }
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-touch-action: pan-Y;
    touch-action: pan-Y;
    padding: 0;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    will-change: transform;
  }
  .glide__slide,
  .glide__slides--dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide__slide {
    width: 100%;
    height: 100%;
    @apply py-3 mb-10;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    white-space: normal;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    @screen lg {
      @apply px-24;
    }
  }
  .glide__slide a {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .glide__arrows,
  .glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide__bullets {
    @apply relative flex flex-no-wrap justify-between;
    @screen lg {
      @apply mt-20 mx-24;
    }
    &:after {
      content: "";
      @apply absolute left-0 right-0 border-t-2 border-copper-200;
      top: 50%;
    }
    .glide__bullet {
      transition: padding 0.25s ease-out;
      @apply p-1 bg-copper-200 rounded-full z-1 outline-none self-center;
      &:first-child {
        @apply ml-10 mr-0;
        @screen lg {
          @apply ml-24 mr-0;
        }
      }
      &:last-child {
        @apply mr-10 ml-0;
        @screen lg {
          @apply mr-24 ml-0;
        }
      }
      &--active {
        @apply bg-copper-300 p-2;
        .glide__bullet--year {
          @apply text-gray-900 text-lg font-bold;
          top: -2rem;
        }
      }
      &--year {
        top: -1.5rem;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
  }
  .glide--rtl {
    direction: rtl;
  }
  .timeline {
    &__title {
      font-size: 7.75rem;
    }
    &__description {
      @apply px-6;
      > * {
        @apply mb-0 leading-relaxed;
      }
    }
  }
}
</style>
