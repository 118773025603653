<template>
  <div class="news-slider">
    <div class="glide__arrows">
      <div class="flex flex-wrap items-center w-full mt-8">
        <h1
          class="mb-0 font-serifBold"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          Latest News
        </h1>
        <hr
          class="flex-1 w-auto mx-4 border border-t-0 border-copper-200"
          data-aos="fade-left"
          data-aos-delay="500"
          data-aos-duration="800"
        />
        <button
          @click="controlTo('<')"
          class="px-3 py-1 mr-5 border rounded focus:outline-none border-copper-300"
          data-aos="fade-left"
          data-aos-delay="600"
          data-aos-duration="800"
        >
          <font-awesome-icon
            class="self-center text-copper-300"
            icon="chevron-left"
          ></font-awesome-icon>
        </button>
        <button
          @click="controlTo('>')"
          class="px-3 py-1 border rounded focus:outline-none border-copper-300"
          data-aos="fade-left"
          data-aos-delay="600"
          data-aos-duration="800"
        >
          <font-awesome-icon
            class="self-center text-copper-300"
            icon="chevron-right"
          ></font-awesome-icon>
        </button>
      </div>
    </div>
    <vue-glide
      :options="options"
      ref="slider"
      data-aos="fade-left"
      data-aos-delay="800"
      data-aos-duration="800"
    >
      <vue-glide-slide v-for="entry in articles" :key="entry.id">
        <a :href="entry.url">
          <div class="relative">
            <picture v-if="entry.srcset">
              <source
                :data-srcset="entry.srcsetWebp"
                data-sizes="100vw"
                type="image/webp"
              />
              <img
                class="lazyload"
                :src="entry.placeholderBox"
                :data-srcset="entry.srcset"
                sizes="100vw"
              />
            </picture>
            <slot name="imageArrowRight"></slot>
          </div>
          <ul class="mb-3">
            <li class="text-gray-500">
              <span class="font-bold">{{ entry.postDate }}</span>
              {{ entry.authorsName ? "| " + entry.authorsName : null }}
            </li>
          </ul>
          <h4 class="mb-0 font-bold">{{ entry.title }}</h4>
        </a>
      </vue-glide-slide>
    </vue-glide>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
export default {
  name: "Slider",
  props: ["articles"],
  data() {
    return {
      options: {
        perView: 1,
        breakpoints: {
          768: {
            peek: {
              before: 0,
              after: 100,
            },
          },
        },
        gap: 45,
        peek: {
          before: 0,
          after: 350,
        },
      },
    };
  },
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  methods: {
    controlTo(dir) {
      this.$refs.slider.glide.go(dir);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.news-slider {
  .glide {
    @apply relative;
  }
  .glide * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-touch-action: pan-Y;
    touch-action: pan-Y;
    padding: 0;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    will-change: transform;
  }
  .glide__slide,
  .glide__slides--dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide__slide {
    width: 100%;
    height: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    white-space: normal;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  .glide__slide a {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .glide__arrows,
  .glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide--rtl {
    direction: rtl;
  }
  .glide__track {
    @apply py-12;
  }
}
</style>
